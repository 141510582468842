.card-container {
    background-color: var(--bg-color-2);
    margin: 0;
    display: grid;
    grid-template-rows: 2rem 2rem 2rem;
    padding: 20px;
    text-align: left;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: var(--border-radius-1);
    border: 1px solid var(--border-color-1);
    box-shadow: var(--shadow-param-1) var(--shadow-color-1);
}

.card-title {
    font-family: 'SourceSansPro';
    font-size: var(--font-size-0);
    color: var(--text-color-1); 
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-2);
    align-items: start;
    margin-top: 0px;
}

.card-content {
    align-self: center;
    font-family: 'SourceSansPro-Black';
    text-align: left;
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-3);
    color: var(--text-color-1);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-block-end: 0px;
}

.card-footnote {
    font-family: 'SourceSansPro';
    font-size: var(--font-size-0);
    letter-spacing: var(--letter-spacing-3);
    color: var(--text-color-4);
    overflow: hidden;
}

.card-footnote a {
    color: var(--text-color-3);
}