.App {
  text-align: center;
  background-image: url("./assets/float/abstract_orange_black_top.svg"),
    url("./assets/float/abstract_orange_black_bottom.svg");
  /* background-image: url("./assets/float/blue_wave.svg"); */
  /* background-size: 155rem, 195rem;
  background-position: -23rem 1rem, center 130%; */
  background-position: left top, left bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: black;
  padding: 2rem 7rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .App {
    background-position: center 11rem, center 140%;
    padding: 0.1rem;
  }
}

.topbar-box-center {
  font-size: var(--font-size-3);
}
