.farm-panel-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 20px 0;
  background-color: var(--bg-color-4);
  border-radius: 3px;
  border: 1px solid var(--border-color-1);
  box-shadow: var(--shadow-param-1) var(--shadow-color-1);
}

.farm-panel-header {
  display: grid;
  grid-template-columns: minmax(0, 4fr) minmax(0px, 1px) minmax(0, 2fr) minmax(
      0px,
      1px
    ) minmax(0, 2fr) minmax(0px, 20px) minmax(0, 3fr);
  text-align: left;
  padding: 20px;
}

.farm-panel-instructions {
  display: grid;
  grid-template-rows: minmax(0, 30px) minmax(0, 30px) minmax(0, 25px);
  grid-template-columns: auto 1fr;
  align-items: center;
}

.farm-panel-instructions h2 {
  margin: 0;
  grid-column: 1;
  grid-row: 1;
  font-family: "SourceSansPro-Black";
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-1);
}

.farm-panel-instructions p {
  margin: 0;
  grid-column: 1;
}

.farm-subtitle {
  grid-row: 3;
  grid-column: 1;
}

.etherscan-container {
  grid-row: 3;
  grid-column: 2;
  text-align: right;
  padding-right: 1rem;
  text-transform: uppercase;
}

.farm-panel-body {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0px, 1px) minmax(0, 2fr);
  background-color: var(--bg-color-1);
}

.farm-deposit-stats {
  display: grid;
  grid-template-rows: minmax(0, 30px) minmax(0, 30px) minmax(0, 25px);
  align-items: center;
  padding-left: 20px;
}

.farm-deposit-stats p {
  margin: 0;
}

.farm-secondary-title {
  font-family: "SourceSansPro";
  font-size: var(--font-size-0);
  color: var(--text-color-1);
  text-emphasis: none;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-2);
  overflow: hidden;
}

.farm-stat {
  font-family: "SourceSansPro-Black";
  font-size: var(--font-size-2);
  text-align: left;
  letter-spacing: var(--letter-spacing-3);
  color: var(--text-color-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.farm-subtitle {
  font-family: "SourceSansPro";
  color: var(--text-color-4);
  font-size: var(--font-size-0);
}

.columnDivider {
  border-right: 0.01em solid var(--bg-color-6);
  margin: 20px 0;
}

.smallColumnDivider {
  border-right: 0.05em solid var(--bg-color-6);
  margin: -5px 0;
}

.farm-panel-footer {
  display: block;
  /* grid-template-columns: minmax(0,4fr) minmax(0px,1px) minmax(0,2fr) minmax(0px,20px) minmax(0,3fr); */
  text-align: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .farm-panel-header {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: 1fr;

    align-items: center;
    text-align: center;
  }

  .farm-panel-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .etherscan-container {
    padding: 0;
  }
  .farm-deposit-stats {
    padding: 0;
  }

  .farm-stat {
    text-align: center;
  }

  .farm-panel-body {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: auto;
  }

  .farm-panel-footer {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: 1fr;

    align-items: center;
    text-align: center;
  }
}
