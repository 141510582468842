.phase-info-container {
  margin-top: 20px;
  background-color: var(--bg-color-1);
  padding: 5px 20px;
  text-align: left;
  box-sizing: border-box;
  border-radius: var(--border-radius-1);
  border: 1px solid var(--border-color-1);
  box-shadow: var(--shadow-param-1) var(--shadow-color-1);
}

.phase-info-content {
  font-family: "SourceSansPro";
  font-size: var(--font-size-1);
  color: var(--text-color-1);
}

.phase-info-title {
  font-family: "SourceSansPro-Semibold";
  color: var(--text-color-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phase-info-title h4 {
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-2);
}

.whitelist-badge {
  color: var(--bg-color-3);
  border-width: 0.1rem;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  transition: all 300ms ease 0s;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  margin: 0;
}
