.splash {
  background-image: linear-gradient(45deg, #06004e, #0c0084);
  background-attachment: fixed;
  height: 100vh;
  overflow: hidden;
}

.splash-title {
  position: absolute;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;
}

.splash-title h1 {
  font-size: 4rem;
  text-align: center;
  padding: 1rem;
  text-shadow: 0px 5px 15px #06004e;
}

.splash video {
  bottom: 0;
  width: 100%;
  margin-top: calc(100vh - 55%);
}

.splash .footer-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding-bottom: 2rem;

}

.splash .footer-col, .splash .footer-col a {
  color: white;
}